let sending = false
let gToken = ''

const sendForm = () => {
  if (!validate()) {
    console.warn('Validation error!')

    document.querySelector('.alert--warn').classList.add('alert--active')
    setTimeout(() => {
      document.querySelector('.alert--warn').classList.remove('alert--active')
    }, 2000)

    return
  }

  if (sending) {
    console.warn('Sending in progress!')
    return
  }

  sending = true
  document.querySelector('.loading').classList.add('loading--active')

  const form = document.getElementById('contact-form')
  const data = new FormData()

  data.append('name', form.name.value)
  data.append('email', form.email.value)
  data.append('phone', form.phone.value)
  data.append('company', form.company.value)
  data.append('msg', form.msg.value)
  data.append('g-recaptcha-response', gToken)

  grecaptcha.ready(function() {
    grecaptcha.execute('6Lf93qUUAAAAADx0uQqNvNZQLk04tIaYRLSVtA-U', { action: 'contact' }).then(token => {
      
      data.append('g-recaptcha-response', token)

      fetch('/mail.php', {
        method: 'post',
        body: data
      })
        .then(response => {
          return response.text()
        })
        .then(response => {
          // console.log(response)

          if (response == ':)') {
            console.log('Message sent!')

            document.querySelector('.alert--success').classList.add('alert--active')
            setTimeout(() => {
              document.querySelector('.alert--success').classList.remove('alert--active')
            }, 2000)

            form.reset()
          } else {
            console.error('Server error!')

            document.querySelector('.alert--failed').classList.add('alert--active')
            setTimeout(() => {
              document.querySelector('.alert--failed').classList.remove('alert--active')
            }, 2000)
          }

          sending = false
          document.querySelector('.loading').classList.remove('loading--active')
        })
    })
  })
}

const validate = () => {
  const form = document.getElementById('contact-form')

  if (form.name.value == '' || form.email.value == '' || form.msg.value == '') {
    return false
  } else {
    return true
  }
}
