const pageScriptsInit = () => {
  document.querySelector('.navigation__trigger').addEventListener('click', toggleMenu)

  document.querySelectorAll('.navigation__links a').forEach(el => {
    el.addEventListener('click', () => {
      if (document.querySelector('.navigation__links').classList.contains('navigation__links--active')) toggleMenu()
    })
  })

  addEventListener('scroll', e => {
    const scrolled = document.all ? iebody.scrollTop : pageYOffset
    if (scrolled > document.documentElement.clientHeight - 100) document.querySelector('.navigation__trigger').classList.add('navigation__trigger--background')
    else document.querySelector('.navigation__trigger').classList.remove('navigation__trigger--background')
  })

  //Gładkie przewijanie
  document.querySelectorAll('a[href^="#"]').forEach(item => {
    item.addEventListener('click', e => {
      let hashval = item.getAttribute('href')
      let target = document.querySelector(hashval)
      if (innerHeight < target.offsetHeight) {
        //Element nie zmieści się na ekranie
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      } else {
        //Element zmieści się
        target.scrollIntoView({
          behavior: 'smooth'
        })
      }

      history.pushState(null, null, hashval)
      e.preventDefault()
    })
  })

  document.getElementById('contact-form').addEventListener('submit', e => {
    e.preventDefault()
    sendForm()
  })

  const video = document.querySelector('.heroimage__bg-video')
  if (video) video.play()

  // Przewijanie do góry
  document.querySelector('.go-top-button').addEventListener('click', () => {
    document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  })

  // Zmiana języka
  document.getElementById('lang-change').addEventListener('change', () => {
    const newLang = document.getElementById('lang-change').value
    const rest = document.getElementById('langs')[newLang].value
    const newPath = `${location.origin}/${newLang}/${rest}`

    location.href = newPath
  })

  document.addEventListener('scroll', checkGoTop)

  console.info('Scripts inited!')
}

document.addEventListener('DOMContentLoaded', () => {
  pageScriptsInit()
})

// Barba
barba.init({
  transitions: [
    {
      leave({ current, next }) {
        return new Promise(res => {
          current.container.classList.add('main-wrapper--exit')
          setTimeout(() => {
            next.container.classList.add('main-wrapper--enter')
            res(true)
          }, 300)
        })
      },
      enter({ current, next }) {
        return new Promise(res => {
          current.container.classList.add('main-wrapper--exit')
          next.container.classList.add('main-wrapper--enter')

          setTimeout(() => {
            next.container.classList.remove('main-wrapper--enter')
            res(true)
          }, 300)
        })
      },
      sync: true,
      after() {
        setTimeout(() => {
          pageScriptsInit()
          checkGoTop()
          const target = location.hash.split('#').length == 2 ? location.hash : 'body'
          document.querySelector(target).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }, 20)
      }
    }
  ]
})

const toggleMenu = () => {
  document.querySelector('.navigation__links').classList.toggle('navigation__links--active')
  document.querySelector('.burger').classList.toggle('burger--active')
}

// Distance scrolled
function dw_getScrollOffsets() {
  const doc = document
  const w = window
  let x, y, docEl

  if (typeof w.pageYOffset === 'number') {
    x = w.pageXOffset
    y = w.pageYOffset
  } else {
    docEl = doc.compatMode && doc.compatMode === 'CSS1Compat' ? doc.documentElement : doc.body
    x = docEl.scrollLeft
    y = docEl.scrollTop
  }
  return { x: x, y: y }
}

const checkGoTop = () => {
  const scrolled = dw_getScrollOffsets()
  if (scrolled.y < innerHeight / 2) {
    document.querySelector('.go-top-button').classList.add('go-top-button--hidden')
  } else {
    document.querySelector('.go-top-button').classList.remove('go-top-button--hidden')
  }
}
